.map-infobox {
	padding: 5px 3px;
	text-align: center;
	font-size: 0.8rem;
	border: 1px solid #333;
	border-radius: 20px;
	font-weight: 500;
	width: 120px;
}

.infobox-place {
	font-size: 12px;
	font-weight: 400;
}

@media (max-width: 500px) {
	.map-infobox {
		font-size: 10px;
		width: 80px;
	}
}
