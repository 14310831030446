.table-container {
	padding: 40px;
	font-size: 15px;
	line-height: 30px;
}

.table-header th {
	color: #fff !important;
}

.table-dupont th {
	background-color: #ff6f69 !important;
}

.table-urbain th {
	background-color: #74a5f7 !important;
}

.table-capey th {
	background-color: #74a5f7 !important;
}

.table-fontaine th {
	background-color: #88d8b0 !important;
}

.table-toutard th {
	background-color: #8b9dc3 !important;
}

.table-coulmeau th {
	background-color: #f2a6fc !important;
}

.table-salvage th {
	background-color: #ffcc5c !important;
}

.table-potut th {
	background-color: #ffcc5c !important;
}

.table-gervaiseau th {
	background-color: #ffcc5c !important;
}

.table-illig th {
	background-color: #ffcc5c !important;
}

.table-zele th {
	background-color: #ffcc5c !important;
}

.table-gerouit th {
	background-color: #ffcc5c !important;
}

.table-beckx th {
	background-color: #ffcc5c !important;
}

.column-map {
	padding: 10px 12px 10px 18px !important;
}

.column-street {
	padding: 10px 18px 10px 12px !important;
}

.grid i {
	font-size: 18px;
}

.button-order {
	float: right;
}

@media (max-width: 500px) {
	.table-container {
		padding: 20px;
		font-size: 10px;
	}

	th,
	td {
		padding: 5px !important;
		line-height: 14px;
	}
}
