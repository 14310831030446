.search-container {
  padding: 10px 0;
}

.search-container .results,
.search-container .search {
  text-align: center;
  margin: auto;
  width: 50%;
  max-width: 500px;
  min-width: 300px;
}

.search-container .ui.icon.input {
  text-align: center;
  margin: auto;
  width: 100%;
  max-width: 500px;
  min-width: 300px;
}

.search-container input {
  border-radius: 50px !important;
}

.search-container i.search.icon {
  max-width: 50px !important;
  min-width: 50px !important;
  width: 50px !important;
}
