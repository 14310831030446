.header-container {
	height: 120px;
	margin-top: 20px;
}

.text-header {
	color: #000159;
	text-align: center;
	line-height: calc(22px + 1vw);
	font-size: calc(14px + 1vw);
	font-weight: 600;
}

@media (max-width: 500px) {
	.text-header {
		padding: 0 100px;
	}
}
