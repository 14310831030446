body,
h1 {
	font-family: 'Quicksand', sans-serif !important;
}

a {
	color: #022f77 !important;
}

a:hover {
	color: #011e4c !important;
}

.app-background {
	min-height: 100%;
	width: 100%;
	height: auto;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	background-image: linear-gradient(
		to right,
		#002395,
		#4d48aa,
		#796fbe,
		#a198d2,
		#c9c3e6,
		#d6c8ec,
		#e3cef1,
		#f0d3f6,
		#f5addd,
		#fd83b4,
		#fd567c,
		#ed2939
	);
	opacity: 0.6;
}

.ui.inverted.menu {
	background-color: #011e4c !important;
}

.sidebar-icon,
.close.icon {
	cursor: pointer;
}

.menu .header {
	font-size: 14px !important;
	margin-top: 20px !important;
}

.text-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.menu .item a,
.menu .header a {
	text-transform: capitalize !important;
	color: rgba(255, 255, 255, 0.6) !important;
	line-height: 20px;
}

.menu .icon-color {
	margin-right: 5px !important;
	color: rgba(255, 255, 255, 0.6) !important;
}

.menu .item a:hover,
.menu .header a:hover {
	color: #fff !important;
}

.sidebar-icon {
	position: absolute;
	top: 20px;
	margin: 100px 0 0 20px !important;
	font-size: 28px !important;
}

.text-center {
	text-align: center !important;
}

.logo-header {
	position: absolute;
	top: 20px;
	margin-left: 20px;
	width: 10vw;
	min-width: 50px;
	max-width: 110px;
	border-radius: 7px;
}

.logo-map {
	right: 20px;
	bottom: 30%;
	position: absolute;
	width: 90px;
	z-index: 10;
}

.logo-excel {
	position: absolute;
	right: 40px;
	top: 50%;
	transform: translateY(-50%);
	width: 60px;
	z-index: 10;
}

.logout-text {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 10;
}

.dashboard-text {
	position: absolute;
	left: 20px;
	bottom: 20px;
	z-index: 10;
}

.login-text {
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
}

.contact-text {
	right: 20px;
	top: 30%;
	position: absolute;
	z-index: 10;
}

.contact-form {
	padding: 40px 30%;
}

.button-form {
	text-align: center;
	padding: 10px;
}

.form-success {
	font-weight: 500;
	padding: 20px 0;
	color: #2185d0;
	text-align: center;
}

.content-container {
	padding: 40px;
	font-size: 19px;
	line-height: 40px;
}

.center-container {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	font-size: 24px;
	line-height: 40px;
	color: #777;
	font-style: italic;
}

.icon-color {
	color: #022f77;
	margin-right: 10px !important;
}

.icon-build {
	margin-bottom: 20px !important;
	margin-left: 20px !important;
}

.text-grey {
	color: #777;
}

.contact-header {
	text-align: center;
	font-size: 16px !important;
	padding-bottom: 40px !important;
}

.diagram-container {
	margin-top: 60px;
}

.marquee {
	margin: 40px auto;
	white-space: nowrap;
	overflow: hidden;
	position: absolute;
	width: 100%;
	font-size: 14px;
	color: #022f77;
}

.marquee span {
	display: inline-block;
	padding-left: 100%;
	animation: marquee 15s linear infinite;
}

.marquee2 span {
	animation-delay: 15s;
}

@keyframes marquee {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-100%, 0);
	}
}

.input-description {
	width: calc(100% - 125px);
	padding: 5px;
}

/* page transition */
.page {
	position: absolute;
	left: 15px;
	right: 15px;
}

.page-enter {
	opacity: 0;
	transform: scale(1.1);
}

.page-enter-active {
	opacity: 1;
	transform: scale(1);
	transition: opacity 300ms, transform 300ms;
}

.page-exit {
	opacity: 1;
	transform: scale(1);
}

.page-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 300ms, transform 300ms;
}

.alert-enter {
	opacity: 0;
	transform: scale(0.9);
}

.alert-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms;
}

.alert-exit {
	opacity: 1;
}

.alert-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 300ms, transform 300ms;
}

.man-adn {
	position: absolute;
	right: 40px;
	top: 50%;
	transform: translateY(-50%);
	width: 20%;
}

.input-file {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

@media (max-width: 500px) {
	.logout-text,
	.contact-text,
	.login-text,
	.logo-map,
	.logo-excel {
		display: none !important;
	}

	.sidebar-icon {
		font-size: 20px !important;
		margin: 50px 0 0 20px !important;
	}

	.man-adn {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		width: 50%;
		padding-bottom: 40px;
	}

	.text-medium {
		font-size: 16px;
		line-height: 24px;
	}

	.menu .text-medium {
		font-size: 13px !important;
	}

	.diagram-container {
		margin-top: 0;
	}
}
